<template>
  <Hero />
  <Searchbar />
  <CtaGrid
    :images="gridVendingMachines.imagesGrid1"
    :title="gridVendingMachines.title"
    :description="gridCollaborations.description"
    :row-reverse="gridCollaborations.rowReverse"
    :button-text="gridCollaborations.buttonText"
  />
  <Cta
    :img-path="cta.imgPath"
    :title="cta.title"
    :description="cta.description"
    :row-reverse="cta.rowReverse"
    :button-text="cta.buttonText"
  />
  <!-- TODO: Unhide when collaborations are known -->
  <CtaGrid
    v-if="false"
    :images="gridCollaborations.imagesGrid2"
    :title="gridCollaborations.title"
    :description="gridCollaborations.description"
    :row-reverse="gridCollaborations.rowReverse"
  />
</template>

<script src="./Home.controller.js">
</script>
<style lang="scss" src="./Home.styles.scss">
</style>
